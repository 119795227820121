
import { ElMessage } from 'element-plus'
import moment from 'moment'
import { defineComponent, reactive, toRefs, ref, watchEffect } from 'vue'
export default defineComponent({
  name: 'CSearchList',
  props: {
    vhasAuth: {
      type: String,
      default: 'query'
    },
    // 传入数据
    inputList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 展开状态
    spreadStatus: {
      type: Boolean,
      default: false
    },
    // 显示几条
    showNumber: {
      type: Number,
      default: 2
    },
    labelPosition: {
      type: String,
      default: 'right'
    },
    // 隐藏重置按钮
    isReset: {
      type: Boolean,
      default: true
    }
  },
  emits: ['on-search', 'change', 'on-reset'],
  setup(props, { slots, emit }) {
    const hasExtendInput = slots && slots.extendInput

    // form dom
    const formRef = ref<HTMLElement | null>(null)

    const data = reactive({
      formData: {},
      rules: []
    })

    // 表单默认赋值
    const formDataInit = () => {
      let res = {}
      props.inputList.forEach(item => {
        const obj = {}
        Object.keys(item).forEach(key => {
          if (key === 'key' && (item as any).value) {
            obj[item[key]] = (item as any).value
          }
        })
        res = Object.assign(data.formData, obj)
      })
      data.formData = res
    }
    formDataInit()

    // 初始条目状态
    const insideShowNumber = ref(0)

    watchEffect(() => {
      insideShowNumber.value = props.showNumber
    })

    /**
     * @description: 展开
     * @param {*}
     * @return {void}
     */
    const spread = () => {
      insideShowNumber.value = props.inputList.length + 1
    }

    /**
     * @description: 收起
     * @param {*}
     * @return {void}
     */
    const less = () => {
      insideShowNumber.value = props.showNumber
    }

    /**
     * @description: 搜索事件派发
     * @param {*}
     * @return {*}
     */
    const search = () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const dom: any = formRef.value
      dom.validate(valid => {
        if (valid) {
          const param: any = Object.assign({}, data.formData)

          // 时间，日期范围数据返回重构，默认不开启兼容前面，当 isArea为ture的时候开启
          props.inputList.forEach((item: any) => {
            if (
              (item.dateType === 'daterange' ||
                item.dateType === 'datetimerange') &&
              item.isArea
            ) {
              // 时间范围
              if (item.keyList && item.formatList) {
                if (
                  param[item.key] &&
                  param[item.key] !== undefined &&
                  param[item.key].length !== 0
                ) {
                  // 赋值时间开始，结束时间字段key值
                  const start = item.keyList[0] || 'startTime'
                  const end = item.keyList[1] || 'endTime'
                  // 赋值时间开始，结束时间返回值的格式
                  const fromatStart = item.formatList[0] || item.format
                  const fromatEnd = item.formatList[1] || item.format
                  param[start] = moment(param[item.key][0]).format(fromatStart)
                  param[end] = moment(param[item.key][1]).format(fromatEnd)
                }
                delete param[item.key]
              } else {
                ElMessage.warning(
                  `${item.label}项 缺少keyList:['开始时间key','结束时间key'],formatList: ['YYYY-MM-DD 00:00:00', 'YYYY-MM-DD 23:59:59']`
                )
              }
            } else if (item.type === 'datePicker' && item.isArea) {
              // 单个时间，根据format输出时间格式
              if (param[item.key]) {
                param[item.key] = moment(param[item.key]).format(item.format)
              }
            } else if (item.type === 'select-group') {
              // select下拉选择，带分组功能
              if (item.keyList) {
                if (param[item.key] && param[item.key] !== undefined) {
                  // 特意给车队分组专用
                  const arr = param[item.key].split('-')
                  const first = item.keyList[0] || 'fleetType'
                  const second = item.keyList[1] || 'fleetUuid'
                  param[first] = arr[0]
                  param[second] = arr[1]
                }
                delete param[item.key]
              } else {
                ElMessage.warning(
                  `${item.label}项 缺少keyList:['分组类型key','车队key']`
                )
              }
            }
          })

          Object.keys(param).forEach(item => {
            if (param[item] === '' || param[item] === null || param[item].length === 0) {
              // 字段为空，删除搜索条件
              delete param[item]
            }
          })

          emit('on-search', param)
        }
      })
    }

    /**
     * @description: 重置事件派发
     * @param {*}
     * @return {*}
     */
    const reset = (t = true) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const dom: any = formRef.value
      dom.resetFields()
      // 时间范围 数组 resetFields 方法清理有问题，故而有此操作
      data.formData = {}
      // 产品希望某些有默认值，重置后也希望重置为默认值 配置项中如果有 value，则不会被重置
      formDataInit()
      if (t) {
        emit('on-reset', {})
      }
    }

    /**
     * @description: select or input change event
     * @param {object | string} data
     * @return {void}
     */
    const selectOrInput = (data: object | string | number | any[]) => {
      emit('change', data)
    }

    return {
      ...toRefs(data),
      formRef,
      search,
      reset,
      selectOrInput,
      spread,
      less,
      insideShowNumber,
      hasExtendInput,
      formDataInit
    }
  }
})
